import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDf2bYkpK-L8p_msK8XC2HNGf2P8uezZGQ",
  authDomain: "my-app-login-6d78d.firebaseapp.com",
  projectId: "my-app-login-6d78d",
  storageBucket: "my-app-login-6d78d.appspot.com",
  messagingSenderId: "823672126188",
  appId: "1:823672126188:web:448de6eac5040fcede83c9",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
export { auth, provider, db, storage };
